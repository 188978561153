import React from "react"
import useTranslations from "../../../utils/useTranslations"
import FeaturesList from "../../../components/featuresList"
import ComparisonPanels from "../../../components/comparisonPanels"

import heroImgWebp from "../../../images/products/heroes/screen-web-win-laptop.webp"
import heroImg from "../../../images/products/heroes/screen-web-win-laptop.png"
import heroImgWebp2x from "../../../images/products/heroes/screen-web-win-laptop@2x.webp"
import heroImg2x from "../../../images/products/heroes/screen-web-win-laptop@2x.png"

import Button from "../../../components/button"
import Logo from "../../../components/logo"
import LocalizedLink from "../../../components/localizedLink"
import backgrounds from "../../../components/backgrounds"

const heroImgAlt = "Download BitTorrent Web for Desktop"

class BittorrentWebFree extends React.Component {

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)
    const { unlocalizedPath }  = this.props.pageContext
    return (
      <>
        
        <div className="top-section-container p-0 pb-md-5 pt-md-3 background-noise-dark" style={backgrounds.darkCorners}>

          <div className="container text-dark py-2">

            <div className="row text-center mt-4 mt-sm-5">
              <div className="col-md-6 mt-md-4 order-md-1 px-md-0">
                <Logo color="black" tag="h1" productName="Web" className="mb-4"/>
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none"></img>
                </picture>
                <div style={{fontSize: '10px'}} className="d-block d-md-none mb-4"><a id="products-win-btweb-free-bt-now-mobile" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>


                <p className="text-size-24 text-spacing-1 font-weight-normal">{t(`Stream torrents while you download.`)} </p>
                <ul className="product-hero-list mt-2">
                	<li>
                		{t(`Search for torrents and download in a few steps.`)}
                	</li>
                	<li>
                		{t(`Immediately play torrent files and magnet links.`)}
                	</li>
                	<li>
                		{t(`Online torrent downloader and player.`)}
                	</li>
                </ul>
                <div>
                  <Button  href={`${unlocalizedPath}#comparison-panels`} id="products-win-btweb-free-hero-cta" className=" btn-primary text-spacing-1 mt-3 mt-md-3 mb-4 button-filled text-spacing-1">{t(`Free Download`)}</Button>
                </div>
              </div>
              <div className="col-md-6 mb-5 order-md-2 d-flex justify-content-center flex-column">
                {/*<img src={heroImg} alt="BitTorrent Web in-client" className="img-fluid d-none d-md-block"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt="BitTorrent Web in-client" className="img-fluid d-none d-md-block"></img>
                </picture>
                <div style={{fontSize: '10px'}} className="d-none d-md-block"><a id="products-win-btweb-free-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>

              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden background-noise-light">
          <div className="bg-text-container bg-text-gray">
            {t(`FeaturesHeader`)}
          </div>
          <div className="container mt-5 z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`FeaturesHeader`)}</p>

            <p className="text-center mb-5 text-size-24" >
              BitTorrent Web {t(`Torrent Client`)} {t(`Features`)}
            </p>

            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>


        <div className="container-fluid position-relative overflow-hidden background-noise-dark" style={backgrounds.darkCorners}>
          <div className="container my-5 text-center z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3 text-dark">{t(`Compare`)}</p>

            <h2 className="text-center mb-5 text-size-24 font-weight-normal text-dark">
                {t(`Compare BitTorrent Web Versions`)}
            </h2>
          </div>
          <ComparisonPanels pageContext={{ "text": text, "pageName":pageName }}>
            <p className="text-dark text-center mt-5">
              <LocalizedLink to="/products/win/bittorrent-web-premium" id="products-win-btweb-free-learnmore-premium" className="font-weight-bold">
                {t(`Learn more`)}
              </LocalizedLink> {t(`about BitTorrent Web premium products`)}
            </p>
          </ComparisonPanels>
        </div>

        <div className="container my-5 pt-5">
          <div className="word-cloud font-weight-bold">
          	BitTorrent <span className="font-weight-normal">
              Web
            </span><span className="font-weight-bold">
              / 
            </span> <br/> <span className="text-size-32">{t(`Product Tip`)}</span>
          </div>
        </div>

        <div className="container my-md-5 py-md-5">
        	<div className="row mb-5 mb-md-5 pb-md-5">
        		<div className="col-md-3 text-md-right product-faq-question mb-3 mb-md-0">
        			{t(`How do I use<br><b>BitTorrent Web?</b>`)}
        		</div>
        		<div className="offset-md-1 col-md-8 product-faq-answer">
        			{t(`<b>BitTorrent Web is an easy-to-use online torrent client</b> that uses your default browser. If you already know where to find your torrent file, you can click on it or drag the torrent into the browser window of BitTorrent Web. Alternatively, you can use the search box at the top of the BitTorrent Web window to search for a torrent download. If you have the safe torrent scanner installed, which is offered to you shortly after installing BitTorrent Web, it helps you find and download torrent files from the search results, meaning you don’t need to visit potentially harmful websites. You can specify the specific hard drive or folder where you want torrents to download.  You can also change the default download folder in BitTorrent Web's settings. If the file is a video or audio file, you can stream torrent files inside the media player built into your browser based web torrent client.`)} 

        		</div>
        	</div>
        	<div className="row mb-5 mb-md-5">
        		<div className="col-md-3 text-md-right product-faq-question mb-3 mb-md-0">
        			{t(`How to stream<br><b>torrent files</b>`)}
        		</div>
        		<div className="offset-md-1 col-md-8 product-faq-answer">
        			{t(`<b>BitTorrent Web makes it easy to stream torrent files.</b> Once installed into your favorite browser, the web torrent client allows you to download any file type into the folder of your choice. However, if the file is audio or video based, you can stream the file within your browser window via the built-in player. Best of all, you don’t need to wait until the file finishes downloading - you can play it near-instantly after the download begins. As you build up your library of downloaded files, you can use BitTorrent Web as your preferred media player to stream online torrents.`)}
        		</div>
        	</div>

        </div>

      </>
    )

  }
}

export default BittorrentWebFree
